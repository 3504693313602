import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SubaruOutback = () => (
  <Layout>
    <SEO title="Disable Subaru Outback Automatic Lift Gate Approach Light Beeping" />
    <h1>How to disable automatic lift gate, rear view approach lights, and beeping on Subaru Outback 2016</h1>
    <p>The Subaru Outback 2016 is a wonderful vehicle.  Lets collaborate on additional mood enhancement functionality.</p>
    <p>Please provide feedback, stories, solutions, links, or anything at all via this <a href="https://form.jotform.com/92566618566168" className="btn lightbox-92566618566168" style={{marginTop: '16px'}}>product enhancement feedback form</a>.</p>
    <h2>Mood Impact</h2>
        <p>Issues listed on the <Link to="/">homepage</Link> are known to annoy neighbors in dark skies and quiet campouts, increase operator stress, and shorten owner's expected lifespan.</p>
    <h2>Prior Discussion</h2>
    <h3>Beeping</h3>
    <p>Goal: Disable all beeps and chirps, with priority on beeps during stationary operation (e.g. manual liftgate operation; driver-side door open while radio in use)</p>
    <h4>Lift gate</h4>
    <p>...</p>
    <h4>Drivers-side Door Chime</h4>
    <ul>
        <li><a href="https://www.reddit.com/r/subaru/comments/79kyit/how_stop_the_door_open_warning_chime_beep_on_a/">How stop the door open warning / chime / beep on a 2016 Subaru Outback</a> - "You can wedge the door sensor to be closed all the time."  <a href="http://www.subaruoutback.org/forums/104-gen-4-2010-2014/44329-oem-remote-start-solution-car-shutting-off-when-door-opened.html">2010-2014 solution instructions for remote start requirements</a></li>
    </ul>
    <h4>Locking and Unlocking</h4>
    <p>...</p>
    <h3>Lights</h3>
    <p>Goal: Disable lighting during stationary operation, with priority on rear-view mirror approach lights.</p>
    <ul>
        <li><a href="https://www.subaruoutback.org/threads/side-mirror-lights-wont-turn-off-when-hatch-is-open-2016-w-eyesight.288282/">SubaruOutback.org: Side Mirror Lights won't turn off when hatch is open</a> - "20 minutes" ; "My husband is in the backcountry ... can't keep the hatch open or the side ... annoying him and other trekkers camped near by; he wants the hatch open to access stuff in the vehicle and organize his sleeping space ... That's a major fail for a backcountry vehicle."</li>
    </ul>
    <h3>Power Liftgate</h3>
    <p>Goal: Find or develop an approach to completely remove and replace power liftgate feature with one that allows manual, silent, dark-sky friendly operation.</p>
        <ul>
            <li><a href="https://www.reddit.com/r/Subaru_Outback/comments/5dc0ke/power_liftgate_issues/">Reddit: Power liftgate issues</a></li>
            <li><a href="https://www.subaruforester.org/threads/rear-power-lift-gate-wont-open-merged-thread-solved.790253/">SubaruForester.org: Rear power lift gate won't open</a> - ... had to take it to the dealer to have it "reset". I asked if that would be something I could do myself if this ever happened again. The answer was no, bring it to them.</li>
            <li><a href="https://www.subaruoutback.org/threads/how-to-disable-auto-power-open-trunk.230298/">SubaruOutback.org: only thing I don't really like is that you have to let the trunk open and close automatically</a></li>
        </ul>
    <h3>Touchscreen Display</h3>
    <p>Goal: Overlay or replace existing touchscreen display with tactile alternatives. Disable and remove touchscreen if possible while retaining full vehicle maintenance and operation functionality.</p>
    <h2>Solution Options</h2>
        <p>Pending community feedback and success stories!</p>
        <p>Posted to <a href="https://www.reddit.com/r/subaru/comments/d4d5cs/disabling_beeps_and_lights_for_backcountry/">/r/subaru/disabling_beeps_and_lights_for_backcountry</a></p>
    <h3>Potential sources</h3>
        <ul>
            <li><a href="https://lpaventure.com/blogs/projects-outback/">LP adventure</a></li>
            <li><a href="https://andersondesign-fab.com/index/">Anderson Design & Fabrication</a></li>
            <li><a href="https://sumoparts.com/">SUMO Parts</a></li>
            <li><a href="https://www.thedrive.com/news/28925/this-oregon-shop-builds-awesome-lifted-subarus-for-off-road-junkies">thedrive.com article</a></li>
            <li><a href="https://gizmodo.com/how-i-turned-my-subaru-outback-into-a-real-adventuremob-1716068985">Gizmodo mod article</a></li>
        </ul>
    <h2>Reference Material</h2>
        <ul>
            <li><a href="/2016MY-Outback-Owners-Manual.pdf">2016MY-Outback-Owners-Manual.pdf</a></li>
            <li><a href="/2016MY-Outback-Quick-Ref.pdf">2016MY-Outback-Quick-Ref.pdf</a></li>
            <li><a href="/2016MY-Outback-EyeSight-Manual.pdf">2016MY-Outback-EyeSight-Manual.pdf</a></li>
            <li><a href="/2016MY-EyeSight-Quick-Ref.pdf">2016MY-EyeSight-Quick-Ref.pdf</a></li>
        </ul>
        <p><img src={'/2016Outback-Owners-Section-9-20.jpg'} /></p>
        <p><img src={'/2016Outback-Owners-Section-9-21.jpg'} /></p>
        <p><img src={'/2016Outback-Owners-Section-9-22.jpg'} /></p>
        <p><img src={'/2016Outback-Owners-Section-9-23.jpg'} /></p>
        <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SubaruOutback
